<template>
  <div>
    <b-overlay
      :show="show"
    >
      <b-row>
        <b-col :cols="isMobile ? 6 : 3">
          <modal-ingreso-conservacion
            :origen-data="'gateway'"
            :button-text="'Ingresar Conservación de Gateway'"
            :modal-titulo="'Ingreso de Conservación de Gateway'"
            :placeholder="'Buscar Gateway...'"
            @refrescar-pagina="loadReport"
          />
        </b-col>
        <b-col cols="1" />
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Estado:</label>
            <v-select
              v-model="etapa"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          style="padding-top: 3px;"
          cols="1"
        >
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            title="Buscar"
            variant="primary"
            class="btn-icon"
            @click="loadReport()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          v-if="!isMobile"
          style="padding-top: 3px;"
          cols="1"
        >
          <br>
          <reporte-general
            :rows="rowsReporte"
            :columns="columnsReporte"
            :titulo-principal="tituloPrincipal"
            :titulo-secundario="tituloSecundario"
            :subtitulo="subtitulo"
            :titulo-descarga="tituloDescarga"
          />
        </b-col>
      </b-row>
      <br v-if="isMobile">
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            style-class="vgt-table condensed"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar',
            }"
            @on-row-dblclick="showDetalle"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'trabajosGateway'">
                {{ formatTrabajosGateway(props.row.trabajosGateway) }}
              </span>
              <span v-else-if="props.column.field === 'opciones'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="generarDocumento(props.row.id)">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Descargar PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ path: `/conservacion-gateway-formulario/${props.row.id}`, query: { action: 'Edit' } }"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Editar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <span v-else-if="props.column.field == 'estado'">
                <b-badge :variant="props.row.estado === 'INGRESADA' ? 'light-success' : 'light-danger'">
                  {{ props.row.estado }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field == 'fechaCreacion'">
                <span>{{ formatoFecha(props.row.fechaCreacion) }}</span>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <div>
        <b-modal
          id="modal-gateway"
          v-model="detalleGateway"
          title="Detalle del Gateway"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
        >
          <detalle-gateway :gateway="gateway" />
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCol,
  BBadge,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  VBTooltip,
  VBPopover,
  BInputGroup,
  BFormDatepicker,
  BOverlay,
  BFormSelect, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import detalleGateway from '@/components/mantenimiento/conservacionGateway/detalleGateway.vue'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  generarReporte,
  findGateway,
  getGateway, formatTrabajosGateway, sortFnTrabajosGateway,
} from '@/utils/mantenimiento/conservacionGateway/conservacionGatewayUtils'
import { formatoFecha, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import {
  getColumns, getRows,
  subtitulo,
  tituloDescarga,
  tituloPrincipal,
  tituloSecundario,
} from '@/utils/reportes/mantenimientoPreventivo/conservacion-gateway'
import ModalIngresoConservacion from '@/components/ModaIngresoFormularios/ModalIngreso.vue'
import { isMobile } from '@/utils/funciones'

export default {
  components: {
    ModalIngresoConservacion,
    ReporteGeneral,
    BCol,
    BModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    detalleGateway,
    BRow,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    VueGoodTable,
    BFormDatepicker,
    BInputGroupAppend,
    BOverlay,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      show: false,
      pageLength: 15,
      usuario: JSON.parse(localStorage.getItem('userData')),
      detalleGateway: false,
      gateway: null,
      fechaInicio: '',
      fechaFin: '',
      etapa: '',
      estado: null,
      option: [
        { title: 'Todos', value: 'TODOS' },
        { title: 'Ingresadas', value: 'INGRESADA' },
        { title: 'Finalizadas', value: 'FINALIZADA' },
      ],
      columns: [
        {
          label: 'Brigada',
          field: 'brigada.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Trabajo(s) Realizado(s)',
          field: 'trabajosGateway',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
          sortable: true,
          sortFn: this.sortFnTrabajosGateway,
        },
        {
          label: 'Fecha Creacion',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'opciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      rows: [],
      columnsReporte: [],
      rowsReporte: [],
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
    isMobile() {
      return isMobile()
    },
  },
  async created() {
    this.rows = []
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
    this.show = false
  },
  methods: {
    formatTrabajosGateway,
    sortFnTrabajosGateway,
    formatoFecha,
    async generarDocumento(id) {
      try {
        const gateway = await getGateway(id)
        const fileResumen = await generarReporte(gateway)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'Conservación de Gateway.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async showDetalle(params) {
      this.gateway = params.row
      this.detalleGateway = true
    },
    async loadReport() {
      this.show = true
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
      // eslint-disable-next-line no-restricted-globals
      const filter = {
        where: {
          and: [
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
            { companyId: this.usuario.idCompany },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      if (this.etapa && this.etapa.value !== '') {
        if (this.etapa.value !== 'TODOS') {
          filter.where.and.push({ estado: this.etapa.value })
        }
      } else {
        filter.where.and.push({ estado: 'INGRESADA' })
      }
      const data = await findGateway(filter)
      this.rowsReporte = getRows([...data])
      // eslint-disable-next-line no-restricted-syntax
      this.show = false
      this.rows = data
      this.columnsReporte = getColumns()
      this.show = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
